import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../components/Layout"
import NotFound from "../components/NotFound"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      not_found: datoCmsNotFound(locale: { eq: "en" }) {
        ...NotFound
      }
    }
  `)

  return (
    <Layout transparentHeader hideNewsletter>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.not_found.locale,
        }}
        seo={data.not_found.seoMetaTags}
      />
      <NotFound data={data.not_found} />
    </Layout>
  )
}

export default NotFoundPage
